const CONTENT_TYPE_OPTIONS = [
  { value: "none", label: "None" },
  { value: "ad", label: "Ad" },
  { value: "iap", label: "IAP" },
];

const APP_EVENTS_ID_OPTIONS = [
  { value: "app_opened", label: "App Opened" },
  { value: "app_resumed", label: "App Resumed" },
];

const AD_TYPE_OPTIONS = [
  { value: "interstitial", label: "Interstitial" },
  { value: "reward", label: "Reward" },
  { value: "open_app", label: "Open App" },
  { value: "small_native", label: "Small Native" },
  { value: "medium_native", label: "Medium Native" },
  { value: "large_native", label: "Large Native" },
  { value: "banner", label: "Banner" },
  { value: "collapsible_banner", label: "Collapsible Banner" },
];

const SCREEN_EVENTS_ID_OPTIONS = [
  { value: "request_success", label: "Request Success" },
  { value: "go_next", label: "Go Next" },
  { value: "header", label: "Header" },
  { value: "interleave", label: "Interleave" },
  { value: "footer", label: "Footer" },
];

const getLabelByValue = (options, value) => {
  const option = options.find(option => option.value === value);
  return option ? option.label : value; // Return value if not found
};

export {
  CONTENT_TYPE_OPTIONS,
  APP_EVENTS_ID_OPTIONS,
  AD_TYPE_OPTIONS,
  SCREEN_EVENTS_ID_OPTIONS,
  getLabelByValue
};
