import { faFaceLaughWink, faTachographDigital, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useState} from 'react'
import { Link, useLocation } from 'react-router-dom'

function Sidebar() {
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState(location.pathname);

    const handleMenuClick = (menu) => {
        setActiveMenu(menu);
    };
    return (
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

            {/* <!-- Sidebar - Brand --> */}
            <Link className="nav-link" to="/portal/dashboard">
                <a className="sidebar-brand d-flex align-items-center justify-content-center" href="#">
                    <div className="sidebar-brand-icon rotate-n-15">
                        <FontAwesomeIcon icon={faFaceLaughWink} size={"2x"} />
                    </div>
                    <div className="sidebar-brand-text mx-3">SB Admin <sup>2</sup></div>
                </a>
            </Link>

            {/* <!-- Divider --> */}
            <hr className="sidebar-divider my-0" />

            {/* <!-- Nav Item - Dashboard --> */}
            <li className="nav-item">
                <Link className="nav-link" to="/portal/dashboard">
                    <FontAwesomeIcon icon={faTachographDigital} style={{ marginRight: "0.5rem" }} />
                    <span>Dashboard</span>
                </Link>
            </li>

            <hr className="sidebar-divider my-0" />

            <li className={`nav-item ${activeMenu === '/portal/fairy-ai-list' ? 'active' : ''}`}>
                <Link className="nav-link" to="/portal/fairy-ai-list" onClick={() => handleMenuClick('/portal/fairy-ai-list')}>
                    <FontAwesomeIcon icon={faUsers} style={{ marginRight: "0.5rem" }} />
                    <span>Fairy Ai</span>
                </Link>
            </li>

            <li className={`nav-item ${activeMenu === '/portal/face-dance-list' ? 'active' : ''}`}>
                <Link className="nav-link" to="/portal/face-dance-list" onClick={() => handleMenuClick('/portal/face-dance-list')}>
                    <FontAwesomeIcon icon={faUsers} style={{ marginRight: "0.5rem" }} />
                    <span>Face Dance</span>
                </Link>
            </li>

            <li className={`nav-item ${activeMenu === '/portal/face-swap-list' ? 'active' : ''}`}>
                <Link className="nav-link" to="/portal/face-swap-list" onClick={() => handleMenuClick('/portal/face-swap-list')}>
                    <FontAwesomeIcon icon={faUsers} style={{ marginRight: "0.5rem" }} />
                    <span>Face Swap</span>
                </Link>
            </li>

            <li className={`nav-item ${activeMenu === '/portal/category-list' ? 'active' : ''}`}>
                <Link className="nav-link" to="/portal/category-list" onClick={() => handleMenuClick('/portal/category-list')}>
                    <FontAwesomeIcon icon={faUsers} style={{ marginRight: "0.5rem" }} />
                    <span>Category</span>
                </Link>
            </li>

          <li className={`nav-item ${activeMenu === '/portal/config-list' ? 'active' : ''}`}>
            <Link className="nav-link" to="/portal/config-list" onClick={() => handleMenuClick('/portal/config-list')}>
              <FontAwesomeIcon icon={faUsers} style={{ marginRight: "0.5rem" }} />
              <span>Config</span>
            </Link>
          </li>

          <li className={`nav-item ${activeMenu === '/portal/monetization' ? 'active' : ''}`}>
            <Link className="nav-link" to="/portal/monetization" onClick={() => handleMenuClick('/portal/monetization')}>
              <FontAwesomeIcon icon={faUsers} style={{ marginRight: "0.5rem" }} />
              <span>Monetization</span>
            </Link>
          </li>
        </ul>
    )
}

export default Sidebar