import axios from 'axios';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

function ConfigEdit() {
    const params = useParams();
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        getConfigData().then(() => {})
    }, [])


    let getConfigData = async () => {
        try {
            const res = await axios.get(`https://picfixer.app/api/config/detail/${params.id}`);
            const config = res.data?.data ?? []
            if (!config) {
                navigate("/portal/config-list")
            } else {
                await myFormik.setValues(config);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            navigate("/portal/config-list")
        }
    }

    const myFormik = useFormik({
        initialValues: {
            code: "",
            main_service: "",
            backup_service: "",
            api_key: "",
            always_show_offer: false,
            free_usage_per_day: 0,
            content_type: "",
            backup_service_countries: "",
            disable_countries: "",
        },
        // Validating Forms while entering the data
        validate: (values) => {
            return {};
        },

        onSubmit: async (values) => {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append("code", values.code);
                formData.append("main_service", values.main_service);
                formData.append("backup_service", values.backup_service);
                formData.append("api_key", values.api_key);
                formData.append("always_show_offer", values.always_show_offer);
                formData.append("free_usage_per_day", values.free_usage_per_day);
                formData.append("content_type", values.content_type);
                formData.append("backup_service_countries", values.backup_service_countries);
                formData.append("disable_countries", values.disable_countries);
                await axios.post(`https://picfixer.app/api/config/update/${params.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                setLoading(false);
                navigate("/portal/config-list")
            } catch (error) {
                setLoading(false);
            }
        }
    })


    const codeListEnum = [
        "remove_people",
        "remove_background",
        "edit_background",
        "enhance_photo",
        "one_touch_remove",
        "restore_old_pic",
        "remove_wire",
        "remove_text",
        "cartoon_ai",
        "anime_ai",
        "ai_filter",
        "face_dance",
        "face_swap"
    ];

    return (
        <>
            <h3>Config - Id : {params.id} </h3>
            <div className='container'>
                <form onSubmit={myFormik.handleSubmit}>
                    <div className='row'>
                        <div className="col-lg-6">
                            <label className='font-weight-bold'>Feature Name</label>
                            <select
                              name='code'
                              value={myFormik.values.code}
                              onChange={myFormik.handleChange}
                              className={`form-control ${myFormik.errors.code ? "is-invalid" : ""}`}
                            >
                                <option value="">----Select----</option>
                                {codeListEnum.map((feature, index) => (
                                  <option key={index} value={feature}>{feature.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}</option>
                                ))}
                            </select>
                            <span style={{ color: "red" }}>{myFormik.errors.code}</span>
                        </div>


                        <div className='col-lg-6'>
                            <label className='font-weight-bold'>Main Service</label>
                            <select
                              name='main_service'
                              value={myFormik.values.main_service}
                              onChange={myFormik.handleChange}
                              className={`form-control ${myFormik.errors.main_service ? "is-invalid" : ""}`}
                            >
                                <option value="">----Select----</option>
                                {["SE", "SEP", "PW", "IS"].map((service, index) => (
                                  <option key={index} value={service}>{service}</option>
                                ))}
                            </select>
                            <span style={{ color: "red" }}>{myFormik.errors.main_service}</span>
                        </div>

                        <div className='col-lg-6'>
                            <label className='font-weight-bold'>Backup Service</label>
                            <select
                              name='backup_service'
                              value={myFormik.values.backup_service}
                              onChange={myFormik.handleChange}
                              className={`form-control ${myFormik.errors.backup_service ? "is-invalid" : ""}`}
                            >
                                <option value="">----Select----</option>
                                {["SE", "SEP", "PW", "IS"].map((service, index) => (
                                  <option key={index} value={service}>{service}</option>
                                ))}
                            </select>
                            <span style={{ color: "red" }}>{myFormik.errors.backup_service}</span>
                        </div>

                        <div className="col-lg-6">
                            <label className='font-weight-bold'>API Key</label>
                            <input
                              name='api_key'
                              value={myFormik.values.api_key}
                              onChange={myFormik.handleChange}
                              type="text"
                              className={`form-control ${myFormik.errors.api_key ? "is-invalid" : ""}`}
                            />
                            <span style={{ color: "red" }}>{myFormik.errors.api_key}</span>
                        </div>

                        <div className='col-lg-6'>
                            <label className='font-weight-bold'>Always Show Offer</label>
                            <input
                              type="checkbox"
                              name="always_show_offer"
                              checked={myFormik.values.always_show_offer}
                              onChange={myFormik.handleChange}
                              className='form-check-input ml-2'
                            />
                            <span style={{ color: "red" }}>{myFormik.errors.always_show_offer}</span>
                        </div>

                        <div className='col-lg-6'>
                            <label className='font-weight-bold'>Free Usage Per Day</label>
                            <input
                              name='free_usage_per_day'
                              value={myFormik.values.free_usage_per_day}
                              onChange={myFormik.handleChange}
                              type="number"
                              className={`form-control ${myFormik.errors.free_usage_per_day ? "is-invalid" : ""}`}
                            />
                            <span style={{ color: "red" }}>{myFormik.errors.free_usage_per_day}</span>
                        </div>

                        <div className='col-lg-6'>
                            <label className='font-weight-bold'>Content Type</label>
                            <select
                              name='content_type'
                              value={myFormik.values.content_type}
                              onChange={myFormik.handleChange}
                              className={`form-control ${myFormik.errors.content_type ? "is-invalid" : ""}`}
                            >
                                <option value="">----Select----</option>
                                {["ad", "iap", "none"].map((service, index) => (
                                  <option key={index} value={service}>{service}</option>
                                ))}
                            </select>
                            <span style={{ color: "red" }}>{myFormik.errors.content_type}</span>
                        </div>

                        <div className="col-lg-6">
                            <label className='font-weight-bold'>Backup Service Countries</label>
                            <input
                              name='backup_service_countries'
                              value={myFormik.values.backup_service_countries}
                              onChange={myFormik.handleChange}
                              type="text"
                              className={`form-control ${myFormik.errors.backup_service_countries ? "is-invalid" : ""}`}
                            />
                            <span style={{ color: "red" }}>{myFormik.errors.backup_service_countries}</span>
                        </div>

                        <div className="col-lg-6">
                            <label className='font-weight-bold'>Disable Countries</label>
                            <input
                              name='disable_countries'
                              value={myFormik.values.disable_countries}
                              onChange={myFormik.handleChange}
                              type="text"
                              className={`form-control ${myFormik.errors.disable_countries ? "is-invalid" : ""}`}
                            />
                            <span style={{ color: "red" }}>{myFormik.errors.disable_countries}</span>
                        </div>

                        <div className='col-lg-12 mt-3'>
                            <input
                              disabled={isLoading}
                              type="submit"
                              value={isLoading ? "Submitting..." : "Save"}
                              className='btn btn-primary'
                            />
                        </div>
                    </div>
                </form>
                {/* {JSON.stringify(myFormik.values)} */}
            </div>
        </>


    )
}

export default ConfigEdit